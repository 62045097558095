body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f0f0;
}

h2 {
  margin: 0;
  padding: 5px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.start-title{
  margin: 2rem;
  padding: 5px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-grow: 1;
}

/* File Upload Container */
.file-upload-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  z-index: 1000;
}

.file-upload-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.file-upload-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0;
}

.file-upload-item label {
  font-weight: bold;
  color: #333;
}

.file-upload-item input[type="file"] {
  display: none;
}

.file-upload-item label::before {
  content: attr(data-label);
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  transition: background-color 0.3s ease;
}

.file-upload-item label:hover::before {
  background-color: #0056b3;
}

.file-upload-item p {
  display: none;
}

button {
  background-color: #007BFF;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

button:hover {
  background-color: #0056b3;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .file-upload-form {
      flex-direction: column;
      gap: 1rem;
  }
}

.spinner {
  position: fixed;
  top: 50%;
  left: 33%;
  transform: translate(-50%, -50%);
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #007BFF;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.popup {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
}

.error {
  position: fixed;
  top: 20px;
  left: 20px;
  background-color: #f44336;
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
}

.class-canvas {
display: flex;
width: 100%;
flex-direction: column;
align-items: center;
font-family: Arial, sans-serif;
}

.page-container {
display: flex;
width: 100%;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
border-radius: 8px;
overflow: hidden;
}

.image-container {
flex: 2;
display: flex;
justify-content: center;
align-items: center;
background-color: #f9f9f9;
}

.pdf-image {
width: 100%;
height: auto;
border-right: 1px solid #ddd;
}

.text-container {
flex: 1;
padding: 20px;
background-color: #ffffff;
}

.lesson-content {
overflow-y: auto;
height: 100%;
}

.lesson-content h2 {
margin-top: 0;
font-size: 24px;
}

.lesson-content p {
margin-top: 0;
font-size: 20px;
line-height: 1.8;
}

.navigation-buttons {
margin-top: 20px;
display: flex;
justify-content: center;
width: 100%;
}

.nav-button {
margin: 0 10px;
padding: 10px 20px;
font-size: 16px;
cursor: pointer;
border: none;
border-radius: 4px;
background-color: #007bff;
color: #fff;
transition: background-color 0.3s;
}

.nav-button:hover {
background-color: #0056b3;
}

.chat-container {
display: flex;
flex-direction: row;
width: 100%;
height: 100%;
max-height: 40rem;
}

.chat-box {
flex: 1;
display: flex;
flex-direction: column;
border: 1px solid #ccc;
padding: 10px;
}

.messages {
flex: 1;
overflow-y: auto;
border-bottom: 1px solid #ccc;
margin-bottom: 10px;
}

.message {
padding: 5px;
border-bottom: 1px solid #f1f1f1;
}

.response-canvas {
flex: 1;
border: 1px solid #ccc;
padding: 10px;
}