.class-container {
  display: flex;
  height: 100vh;
}

.class-content {
  width: 66.67%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.lesson-and-chat {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.lesson-data, .chat-container {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  border-top: 1px solid #ccc;
  background-color: #f5f5f5;
}

.image-data {
  margin-top: 20px;
}

.image-data img {
  width: 85%;
  height: auto;
}

.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .class-container {
    flex-direction: column;
  }

  .class-content, .lesson-and-chat {
    width: 100%;
  }

  .lesson-data, .chat-container {
    height: 50%;
  }
}

.generate-audio-container {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}

.generate-audio-container button {
  margin: 0 1rem;
}