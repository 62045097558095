/* ChatBox Container */
.chat-box {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

/* Chat Header */
.chat-header {
  background-color: #006400;
  color: #fff;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

/* Chat Messages Container */
.chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #e8f5e9;
  margin-bottom: 8rem;
}

/* Individual Chat Message */
.chat-message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
  max-width: 80%;
  word-wrap: break-word;
}

/* User Messages */
.chat-message.user {
  background-color: #daf1da;
  align-self: flex-end;
}

/* Bot Messages */
.chat-message.bot {
  background-color: #f1f1f1;
  align-self: flex-start;
}

/* Chat Input Container */
.chat-input {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5rem;
  padding-right: 2rem;
}

/* Chat Input Field */
.chat-input input {
  flex: 1;
  border: none;
  padding: 10px;
  outline: none;
  border-radius: 4px;
  margin-right: 10px;
  background-color: #f5f5f5;
}

/* Send Button */
.chat-input button {
  background-color: #006400;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 5px;
}

.chat-input button:hover {
  background-color: #004d00;
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .chat-box {
    height: 50%;
  }

  .chat-input {
    width: 100%;
  }
}